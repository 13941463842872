import { useState } from 'react';
import { Modal, Input, Button, ModalHeader, ModalBody, ModalFooter, ModalContent } from "@nextui-org/react";
import { createSubTask } from '../services/subTaskService'; // Import the createSubTask function

export default function AddSubTaskModal({ isOpen, onOpenChange, initialFeedbackId = "", initialSubTaskId = "" }) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [feedbackId, setFeedbackId] = useState(initialFeedbackId);
    const [subTaskId, setSubTaskId] = useState(initialSubTaskId);

    const handleCreateSubTask = async () => {
        const missingFields = [];
        if (!name) missingFields.push("Name");
        if (!description) missingFields.push("Description");
        if (!feedbackId && !subTaskId) missingFields.push("Feedback ID or Sub-Task ID");

        if (missingFields.length > 0) {
            console.error(`Missing required fields: ${missingFields.join(", ")}.`);
            return;
        }

        try {
            const subTaskData = {
                name: name,
                description: description,
                feedback_id: feedbackId || undefined,
                sub_task_id: subTaskId || undefined,
                status: "proposed" // Assuming default status
            };

            const response = await createSubTask(subTaskData);
            console.log("Sub-task created successfully:", response);
        } catch (error) {
            console.error("Failed to create sub-task:", error);
        } finally {
            resetForm();
        }
    };

    const resetForm = () => {
        setName("");
        setDescription("");
        setFeedbackId(initialFeedbackId);
        setSubTaskId(initialSubTaskId);
        onOpenChange(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement='top-center'
            scrollBehavior='inside'
            style={{
                minWidth: '90%',
                minHeight: '100px',
                maxWidth: '600px',
                margin: '0 auto',
                padding: '10px'
            }}
        >
            <ModalContent>
                <ModalHeader>Add Sub-Task</ModalHeader>
                <ModalBody>
                    <Input
                        label="Name"
                        placeholder="Enter sub-task name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="bordered"
                        fullWidth
                    />
                    <Input
                        label="Description"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="bordered"
                        fullWidth
                    />
                </ModalBody>
                <ModalFooter>
                    <Button auto flat color="default" onClick={() => onOpenChange(false)}>
                        Cancel
                    </Button>
                    <Button auto onClick={handleCreateSubTask} color="primary">
                        Create
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}