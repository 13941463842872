import * as React from "react";
import { NextUIProvider } from "@nextui-org/system";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from "./pages/Dashboard";
import FeedbackPage from "./pages/FeedbackPage"; // Import the FeedbackPage component
import SubTaskPage from "./pages/SubTaskPage";

function App() {
  return (
    <NextUIProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/feedback/:id" element={<FeedbackPage />} />
          <Route path="/sub-task/:id" element={<SubTaskPage />} /> 
        </Routes>
      </Router>
    </NextUIProvider>
  );
}

export default App;