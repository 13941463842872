import { useState } from 'react';
import { Modal, Input, Button, ModalHeader, ModalBody, ModalFooter, ModalContent } from "@nextui-org/react";
import { createContext } from '../services/contextService';

export default function AddContextModal({ isOpen, onOpenChange, initialFeedbackId = "", initialSubTaskId = "" }) {
    const [feedbackId, setFeedbackId] = useState(initialFeedbackId);
    const [subTaskId, setSubTaskId] = useState(initialSubTaskId);
    const [description, setDescription] = useState("");
    const [screenshotFile, setScreenshotFile] = useState(null);
    const [link, setLink] = useState("");

    const handleCreateContext = async () => {
        const missingFields = [];
        if (!feedbackId && !subTaskId) missingFields.push("Feedback ID or Sub-task ID");
        if (!description) missingFields.push("Description");

        if (missingFields.length > 0) {
            console.error(`Missing required fields: ${missingFields.join(", ")}.`);
            return;
        }

        try {
            const contextData = {
                description: description,
                link: link,
                screenshot_content: null,
                screenshot_filename: null,
                s3_bucket_name: 'paraform-recruiting'
            };

            if (subTaskId) {
                contextData.sub_task_id = subTaskId;
            }

            if (feedbackId) {
                contextData.feedback_id = feedbackId;
            }

            if (screenshotFile) {
                const reader = new FileReader();
                reader.onload = async function (e) {
                    const base64Content = e.target.result.split(',')[1]; // Get base64 content
                    contextData.screenshot_content = base64Content;
                    contextData.screenshot_filename = screenshotFile.name;

                    // Move the createContext call here
                    const response = await createContext(contextData);
                    console.log("Context created successfully:", response);
                };
                reader.readAsDataURL(screenshotFile);
            } else {
                // If no screenshotFile, call createContext directly
                const response = await createContext(contextData);
                console.log("Context created successfully:", response);
            }
        } catch (error) {
            console.error("Failed to create context:", error);
        } finally {
            resetForm();
        }
    };

    const resetForm = () => {
        setFeedbackId(initialFeedbackId);
        setSubTaskId(initialSubTaskId);
        setDescription("");
        setScreenshotFile(null);
        setLink("");
        onOpenChange(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement='top-center'
            scrollBehavior='inside'
            style={{
                minWidth: '90%',
                minHeight: '100px',
                maxWidth: '600px',
                margin: '0 auto',
                padding: '10px'
            }}
        >
            <ModalContent>
                <ModalHeader>Add Context</ModalHeader>
                <ModalBody>
                    <Input
                        label="Description"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="bordered"
                        fullWidth
                    />
                    <Input
                        label="Link"
                        placeholder="Enter link"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        variant="bordered"
                        fullWidth
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setScreenshotFile(e.target.files[0])}
                        style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            padding: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            backgroundColor: '#f9f9f9',
                            cursor: 'pointer'
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button auto flat color="default" onClick={() => onOpenChange(false)}>
                        Cancel
                    </Button>
                    <Button auto onClick={handleCreateContext} color="primary">
                        Create
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}