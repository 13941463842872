import React, { useEffect, useState, useCallback } from 'react';
import FeedbackCard from '../components/FeedbackCard';
import AddFeedbackModal from '../components/AddFeedbackModal';
import { fetchFeedbacksByStatus } from '../services/feedbackService';
import { Tabs, Tab, Button } from '@nextui-org/react';

/**
 * Dashboard component to display feedback entries based on their status.
 * Utilizes tabs to filter feedbacks by status.
 */
function Dashboard() {
    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('proposed');
    const [selectedTab, setSelectedTab] = useState('proposed');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSensitiveEnabled, setIsSensitiveEnabled] = useState(false);

    const loadFeedbacks = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchFeedbacksByStatus(status);
            setFeedbacks(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [status]);

    const checkExtensionInstalled = useCallback((extensionId) => {
        if (!window.chrome || !chrome.runtime || !chrome.runtime.sendMessage) {
            setIsSensitiveEnabled(false);
            return;
        }

        chrome.runtime.sendMessage(extensionId, { message: "ping" }, (response) => {
            if (chrome.runtime.lastError) {
                setIsSensitiveEnabled(false);
            } else {
                setIsSensitiveEnabled(true);
            }
        });
    }, []);

    useEffect(() => {
        loadFeedbacks();
        const isSensitiveEnabled = document.cookie.includes('sensitiveFeature=true');
        setIsSensitiveEnabled(isSensitiveEnabled);
    }, [loadFeedbacks, checkExtensionInstalled]);

    const handleTabChange = (newStatus) => {
        setStatus(newStatus);
        setSelectedTab(newStatus);
    };

    const renderFeedbacks = () => (
        <div style={feedbackGridStyle}>
            {feedbacks.map(feedback => (
                <FeedbackCard
                    key={feedback._id}
                    description={feedback.description}
                    category={feedback.category}
                    status={feedback.status}
                    updated_date={feedback.updated_date}
                    _id={feedback._id}
                />
            ))}
        </div>
    );

    if (loading) {
        return <p style={loadingStyle}>Loading feedbacks...</p>;
    }

    if (error) {
        return <p style={errorStyle}>Error loading feedbacks: {error}</p>;
    }

    return (
        <div style={dashboardStyle}>
            <h1 style={headerStyle}>Feedback Dashboard</h1>
            <Button auto onClick={() => setIsModalOpen(true)} color="primary" style={{ marginBottom: '20px' }}>
                Add Feedback
            </Button>
            <div className="flex w-full flex-col">
                <Tabs selectedKey={selectedTab} onSelectionChange={handleTabChange}>
                    {['proposed', 'in-progress', 'in-refinement', 'done', ...(isSensitiveEnabled ? ['sensitive'] : [])].map(tabStatus => (
                        <Tab key={tabStatus} title={capitalize(tabStatus)} value={tabStatus}>
                            {renderFeedbacks()}
                        </Tab>
                    ))}
                </Tabs>
            </div>
            <AddFeedbackModal isOpen={isModalOpen} onOpenChange={setIsModalOpen} />
        </div>
    );
}

const feedbackGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '15px'
};

const loadingStyle = {
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '18px',
    color: '#555'
};

const errorStyle = {
    textAlign: 'center',
    color: 'red',
    marginTop: '20px',
    fontSize: '18px'
};

const dashboardStyle = {
    padding: '20px',
    maxWidth: '100%',
    margin: '10px auto',
    backgroundColor: '#ffffff',
    borderRadius: '15px',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)'
};

const headerStyle = {
    textAlign: 'center',
    marginBottom: '30px',
    color: '#222',
    fontSize: '28px',
    fontWeight: 'bold',
    letterSpacing: '1px'
};

/**
 * Capitalizes the first letter of a string.
 * @param {string} str - The string to capitalize.
 * @returns {string} - The capitalized string.
 */
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).replace(/[^a-zA-Z0-9]/g, ' ');
}

export default Dashboard;