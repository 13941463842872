import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // Import Link
import { fetchFeedbackById, updateFeedback } from '../services/feedbackService';
import ContextItem from '../components/ContextItem';
import AddAcceptanceCriteriaModal from '../components/AddAcceptanceCriteriaModal';
import AddContextModal from '../components/AddContextModal';
import AddSubTaskModal from '../components/AddSubTaskModal'; // Import the AddSubTaskModal

function FeedbackPage() {
    const { id: feedbackId } = useParams();
    const [feedback, setFeedback] = useState(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(feedback?.status);
    const [description, setDescription] = useState(feedback?.description);
    const [isAcceptanceModalOpen, setAcceptanceModalOpen] = useState(false);
    const [isSubTaskModalOpen, setSubTaskModalOpen] = useState(false);
    const [isContextModalOpen, setContextModalOpen] = useState(false);

    useEffect(() => {
        async function getFeedback() {
            try {
                const data = await fetchFeedbackById(feedbackId);
                setFeedback(data);
                setStatus(data?.status);
                setDescription(data?.description);
            } catch (err) {
                setError(err.message);
            }
        }

        getFeedback();
    }, [feedbackId]);

    const handleStatusChange = async (newStatus) => {
        setStatus(newStatus);
        try {
            await updateFeedback(feedbackId, { ...feedback, status: newStatus });
            setFeedback((prevFeedback) => ({ ...prevFeedback, status: newStatus }));
        } catch (error) {
            console.error('Failed to update status:', error);
        }
    };

    const handleDescriptionChange = async (newDescription) => {
        setDescription(newDescription);
        try {
            await updateFeedback(feedbackId, { ...feedback, description: newDescription });
            setFeedback((prevFeedback) => ({ ...prevFeedback, description: newDescription }));
        } catch (error) {
            console.error('Failed to update description:', error);
        }
    };

    if (error) {
        return <div style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}>Error: {error}</div>;
    }

    if (!feedback) {
        return <div style={{ textAlign: 'center', marginTop: '20px' }}>Loading...</div>;
    }

    return (
        <div style={{
            maxWidth: '95%',
            margin: '20px auto',
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)',
            '@media (max-width: 600px)': {
                padding: '10px',
                fontSize: '14px'
            }
        }}>
            <h1 style={{
                textAlign: 'center',
                marginBottom: '20px',
                color: '#222',
                fontSize: '24px',
                fontWeight: 'bold',
                letterSpacing: '1px',
                '@media (max-width: 600px)': {
                    fontSize: '20px'
                }
            }}>Feedback Details</h1>
            <div style={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                padding: '15px',
                backgroundColor: '#f9f9f9',
                '@media (max-width: 600px)': {
                    padding: '10px'
                }
            }}>
                <div style={{ marginBottom: '10px' }}>
                    <h2>Acceptance Criteria <span style={{ cursor: 'pointer', color: '#0070f3', fontSize: '16px', marginTop: '5px' }} onClick={() => setAcceptanceModalOpen(true)}>+</span></h2>
                    {feedback.acceptance_criteria && feedback.acceptance_criteria.length > 0 ? (
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
                            {feedback.acceptance_criteria.map(criteria => (
                                <li key={criteria._id} style={{ marginBottom: '8px', fontSize: '14px', color: '#333' }}>
                                    {criteria.description}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p style={{ fontSize: '14px', color: '#777' }}>No acceptance criteria available.</p>
                    )}
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <p><strong>ID:</strong> {feedback._id}</p>
                    <p><strong>Description:</strong>
                        <textarea
                            value={description}
                            onChange={(e) => handleDescriptionChange(e.target.value)}
                            style={{ marginLeft: '10px', width: '80%', height: '100px', whiteSpace: 'pre-wrap' }}
                        />
                    </p>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <p><strong>Category:</strong> {feedback.category}</p>
                    <p><strong>Status:</strong>
                        <select
                            value={status}
                            onChange={(e) => handleStatusChange(e.target.value)}
                            style={{ marginLeft: '10px' }}
                        >
                            <option value="proposed">Proposed</option>
                            <option value="in-progress">In Progress</option>
                            <option value="in-refinement">In Refinement</option>
                            <option value="done">Done</option>
                        </select>
                    </p>
                    <p><strong>Urgency:</strong> {feedback.urgency}</p>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <p><strong>Updated Date:</strong> {new Date(feedback.updated_date).toLocaleString()}</p>
                    <p><strong>Updated By:</strong> {feedback.updated_by}</p>
                    <h2>Subtasks <span style={{ cursor: 'pointer', color: '#0070f3', fontSize: '16px', marginTop: '5px' }} onClick={() => setSubTaskModalOpen(true)}>+</span></h2>
                    {feedback.sub_tasks && feedback.sub_tasks.length > 0 ? (
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
                            {feedback.sub_tasks.map(subTask => (
                                <li key={subTask._id} style={{ marginBottom: '8px', fontSize: '14px', color: '#333' }}>
                                    <Link to={`/sub-task/${subTask._id}`} style={{ color: '#0070f3', textDecoration: 'none' }}>
                                        {subTask.status === 'done' ? <s>{subTask.name} - {subTask.status}</s> : `${subTask.name} - ${subTask.status}`}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p style={{ fontSize: '14px', color: '#777' }}>No sub-tasks available.</p>
                    )}
                </div>
                <div style={{ marginTop: '15px' }}>
                    <h2 style={{ color: '#555', fontSize: '20px', fontWeight: 'bold' }}>Contexts <span style={{ cursor: 'pointer', color: '#0070f3', fontSize: '16px', marginTop: '5px' }} onClick={() => setContextModalOpen(true)}>+</span></h2>
                    {feedback.contexts && feedback.contexts.length > 0 ? (
                        feedback.contexts.map(context => (
                            <ContextItem
                                key={context._id}
                                description={context.description}
                                screenshotUrl={context?.screenshot_url}
                                link={context?.link}
                            />
                        ))
                    ) : (
                        <p style={{ fontSize: '14px', color: '#777' }}>No contexts available.</p>
                    )}
                </div>
            </div>

            <AddAcceptanceCriteriaModal
                isOpen={isAcceptanceModalOpen}
                onOpenChange={setAcceptanceModalOpen}
                initialFeedbackId={feedbackId}
            />

            <AddContextModal
                isOpen={isContextModalOpen}
                onOpenChange={setContextModalOpen}
                initialFeedbackId={feedbackId}
            />

            <AddSubTaskModal
                isOpen={isSubTaskModalOpen}
                onOpenChange={setSubTaskModalOpen}
                initialFeedbackId={feedbackId}
            />
        </div>
    );
}

export default FeedbackPage;