const CONTEXT_API_URL = 'https://messaging-service.cypress-software-solutions.com/api/v1/feedback';

/**
 * Creates a context entry.
 * 
 * @param {FormData} contextData - The form data containing context details and file.
 * @returns {Promise<Object>} A promise that resolves to the response from the server.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function createContext(contextData) {
    try {
        const response = await fetch(`${CONTEXT_API_URL}/create-context`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contextData)
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to create context. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while creating context: ${error.message}`);
        throw error;
    }
}

export {
    createContext
}