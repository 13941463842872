import { useState } from 'react';
import { Modal, Input, Button, ModalHeader, ModalBody, ModalFooter, ModalContent } from "@nextui-org/react";
import { createAcceptanceCriteria } from '../services/acceptanceCriteriaService';

export default function AddAcceptanceCriteriaModal({ isOpen, onOpenChange, initialFeedbackId = "", initialSubTaskId = "" }) {
    const [feedbackId, setFeedbackId] = useState(initialFeedbackId);
    const [subTaskId, setSubTaskId] = useState(initialSubTaskId);
    const [description, setDescription] = useState("");

    const handleCreateCriteria = async () => {
        const missingFields = [];
        if (!feedbackId && !subTaskId) missingFields.push("Feedback ID or Sub-task ID");
        if (!description) missingFields.push("Description");

        if (missingFields.length > 0) {
            console.error(`Missing required fields: ${missingFields.join(", ")}.`);
            return;
        }

        try {
            const criteriaData = {
                description: description
            };

            if (feedbackId) {
                criteriaData.feedback_id = feedbackId;
            }

            if (subTaskId) {
                criteriaData.sub_task_id = subTaskId;
            }

            const response = await createAcceptanceCriteria(criteriaData);
            console.log("Acceptance criteria created successfully:", response);
        } catch (error) {
            console.error("Failed to create acceptance criteria:", error);
        } finally {
            resetForm();
        }
    };

    const resetForm = () => {
        setFeedbackId(initialFeedbackId);
        setSubTaskId(initialSubTaskId);
        setDescription("");
        onOpenChange(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement='top-center'
            scrollBehavior='inside'
            style={{
                minWidth: '90%',
                minHeight: '100px',
                maxWidth: '600px',
                margin: '0 auto',
                padding: '10px'
            }}
        >
            <ModalContent>
                <ModalHeader>Create Acceptance Criteria</ModalHeader>
                <ModalBody>
                    <Input
                        label="Description"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="bordered"
                        fullWidth
                    />
                </ModalBody>
                <ModalFooter>
                    <Button auto flat color="default" onClick={() => onOpenChange(false)}>
                        Cancel
                    </Button>
                    <Button auto onClick={handleCreateCriteria} color="primary">
                        Create
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}