const SUB_TASK_API_URL = 'https://messaging-service.cypress-software-solutions.com/api/v1/feedback';

/**
 * Creates a sub-task entry.
 * 
 * @param {Object} subTaskData - The data for the sub-task to be created.
 * @returns {Promise<Object>} A promise that resolves to the response from the server.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function createSubTask(subTaskData) {
    try {
        const response = await fetch(`${SUB_TASK_API_URL}/create-sub-task`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(subTaskData)
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to create sub-task. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while creating sub-task: ${error.message}`);
        throw error;
    }
}

/**
 * Fetches a sub-task by its ID.
 * 
 * @param {string} subTaskId - The ID of the sub-task to fetch.
 * @returns {Promise<Object>} A promise that resolves to the sub-task data.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function fetchSubTaskById(subTaskId) {
    try {
        const response = await fetch(`${SUB_TASK_API_URL}/get-sub-task/${subTaskId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to fetch sub-task. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while fetching sub-task: ${error.message}`);
        throw error;
    }
}


/**
 * Updates a sub-task entry by its ID using a POST request.
 * 
 * @param {string} subTaskId - The ID of the sub-task to update.
 * @param {Object} payload - The payload containing fields to update in the sub-task entry.
 * @returns {Promise<Object>} A promise that resolves to the updated sub-task entry.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function updateSubTask(subTaskId, payload) {
    try {
        const response = await fetch(`${SUB_TASK_API_URL}/update-sub-task`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sub_task_id: subTaskId, payload })
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to update sub-task. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while updating sub-task: ${error.message}`);
        throw error;
    }
}

export {
    createSubTask,
    fetchSubTaskById,  // Export the new function
    updateSubTask      // Export the updated function
}