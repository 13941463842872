import React from 'react';
import { Card, CardHeader, CardBody, Divider, Link } from "@nextui-org/react";

function FeedbackCard({ description, category, status, updated_date, _id }) {
    return (
        <Card style={{ padding: '10px', margin: '5px' }}>
            <CardBody>
                <CardHeader>
                    <div>
                        <Link href={`/feedback/${_id}`} style={{ fontSize: '14px' }}>
                            Feedback ID: {_id}
                        </Link>
                    </div>
                </CardHeader>
                <Divider />
                <div style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                    <strong>Description:</strong>
                    <p>{description}</p>
                </div>
                <p style={{ fontSize: '14px' }}><strong>Category:</strong> {category}</p>
                <p style={{ fontSize: '14px' }}><strong>Status:</strong> {status}</p>
                <p style={{ fontSize: '14px' }}><strong>Updated Date:</strong> {updated_date}</p>
            </CardBody>
        </Card>
    );
}

export default FeedbackCard;