const ACCEPTANCE_CRITERIA_API_URL = 'https://messaging-service.cypress-software-solutions.com/api/v1/feedback';

/**
 * Creates an acceptance criteria entry.
 * 
 * @param {Object} criteriaData - The data for the acceptance criteria.
 * @param {string} criteriaData.feedback_id - The ID of the associated feedback entry.
 * @param {string} criteriaData.sub_task_id - The ID of the associated sub-task entry.
 * @param {string} criteriaData.description - The detailed description of the acceptance criteria.
 * @returns {Promise<Object>} A promise that resolves to the response from the server.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function createAcceptanceCriteria(criteriaData) {
    try {
        const response = await fetch(`${ACCEPTANCE_CRITERIA_API_URL}/create-acceptance-criteria`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(criteriaData)
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to create acceptance criteria. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while creating acceptance criteria: ${error.message}`);
        throw error;
    }
}

export {
    createAcceptanceCriteria
}