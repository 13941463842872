import React from 'react';

function ContextItem({ description, screenshotUrl, link }) {
    return (
        <div style={{ marginBottom: '20px', padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#fff', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <p><strong>Description:</strong> {description}</p>
            {screenshotUrl ? (
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', overflow: 'hidden', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                    <img src={screenshotUrl} alt="Screenshot" style={{ maxWidth: '100%', maxHeight: '400px', width: 'auto', height: 'auto' }} />
                </div>
            ) : link ? (
                <p><strong>Link:</strong> <a href={link} target="_blank" rel="noopener noreferrer">{link}</a></p>
            ) : null}
        </div>
    );
}

export default ContextItem;