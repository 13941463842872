const FEEDBACK_API_URL = 'https://messaging-service.cypress-software-solutions.com/api/v1/feedback';

/**
 * Fetches feedback entries by status.
 * 
 * @param {string} status - The status to filter feedbacks by.
 * @returns {Promise<Array>} A promise that resolves to an array of feedback entries.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function fetchFeedbacksByStatus(status) {
    try {
        const response = await fetch(`${FEEDBACK_API_URL}/get-feedbacks?status=${status}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to fetch feedbacks by status. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while fetching feedbacks by status: ${error.message}`);
        throw error;
    }
}

/**
 * Fetches a single feedback entry by its ID.
 * 
 * @param {string} feedbackId - The ID of the feedback to retrieve.
 * @returns {Promise<Object>} A promise that resolves to the feedback entry.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function fetchFeedbackById(feedbackId) {
    try {
        const response = await fetch(`${FEEDBACK_API_URL}/get-feedback/${feedbackId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to fetch feedback. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while fetching feedback: ${error.message}`);
        throw error;
    }
}

/**
 * Updates a feedback entry by its ID using a POST request.
 * 
 * @param {string} feedbackId - The ID of the feedback to update.
 * @param {Object} payload - The payload containing fields to update in the feedback entry.
 * @returns {Promise<Object>} A promise that resolves to the updated feedback entry.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function updateFeedback(feedbackId, payload) {
    try {
        const response = await fetch(`${FEEDBACK_API_URL}/update-feedback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ feedback_id: feedbackId, payload })
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to update feedback. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while updating feedback: ${error.message}`);
        throw error;
    }
}

/**
 * Creates a new feedback entry.
 * 
 * @param {Object} feedbackData - The data for the feedback to be created.
 * @returns {Promise<Object>} A promise that resolves to the created feedback entry.
 * @throws {Error} If the HTTP request fails or the response is not OK.
 */
async function createFeedback(feedbackData) {
    try {
        const response = await fetch(`${FEEDBACK_API_URL}/create-feedback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(feedbackData)
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Failed to create feedback. Status: ${response.status}, Body: ${errorBody}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error(`An error occurred while creating feedback: ${error.message}`);
        throw error;
    }
}

export {
    fetchFeedbacksByStatus,
    fetchFeedbackById,
    updateFeedback,
    createFeedback // Export the new function
}