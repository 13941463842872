import { useState } from 'react';
import { Modal, Input, Button, ModalHeader, ModalBody, ModalFooter, ModalContent } from "@nextui-org/react";
import { createFeedback } from '../services/feedbackService'; // Assume this service exists

export default function AddFeedbackModal({ isOpen, onOpenChange }) {
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("usability");
    const [urgency, setUrgency] = useState("low");
    const [screenshotFile, setScreenshotFile] = useState(null);

    const handleCreateFeedback = async () => {
        const missingFields = [];
        if (!description) missingFields.push("Description");

        if (missingFields.length > 0) {
            console.error(`Missing required fields: ${missingFields.join(", ")}.`);
            return;
        }

        try {
            const feedbackData = {
                description: description,
                category: category,
                urgency: urgency,
                screenshot_content: null,
                screenshot_filename: null,
                s3_bucket_name: 'paraform-recruiting'
            };

            if (screenshotFile) {
                const reader = new FileReader();
                reader.onload = async function (e) {
                    const base64Content = e.target.result.split(',')[1];
                    feedbackData.screenshot_content = base64Content;
                    feedbackData.screenshot_filename = screenshotFile.name;

                    const response = await createFeedback(feedbackData);
                    console.log("Feedback created successfully:", response);
                };
                reader.readAsDataURL(screenshotFile);
            } else {
                const response = await createFeedback(feedbackData);
                console.log("Feedback created successfully:", response);
            }
        } catch (error) {
            console.error("Failed to create feedback:", error);
        } finally {
            resetForm();
        }
    };

    const resetForm = () => {
        setDescription("");
        setCategory("usability");
        setUrgency("low");
        setScreenshotFile(null);
        onOpenChange(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement='top-center'
            scrollBehavior='inside'
            style={{
                minWidth: '90%',
                minHeight: '100px',
                maxWidth: '600px',
                margin: '0 auto',
                padding: '10px'
            }}
        >
            <ModalContent>
                <ModalHeader>Add Feedback</ModalHeader>
                <ModalBody>
                    <Input
                        label="Description"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="bordered"
                        fullWidth
                    />
                    <select
                        label="Category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        style={{
                            width: '100%',
                            padding: '14px',
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            marginBottom: '20px'
                        }}
                    >
                        <option value="usability">Usability</option>
                        <option value="functionality">Functionality</option>
                        <option value="performance">Performance</option>
                        <option value="professionalization">Professionalization</option>
                        <option value="bugs">Bugs</option>
                        <option value="robustness">Robustness</option>
                        <option value="intuitiveness">Intuitiveness</option>
                    </select>
                    <select
                        label="Urgency"
                        value={urgency}
                        onChange={(e) => setUrgency(e.target.value)}
                        style={{
                            width: '100%',
                            padding: '14px',
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            marginBottom: '20px'
                        }}
                    >
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                    </select>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setScreenshotFile(e.target.files[0])}
                        style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            padding: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            backgroundColor: '#f9f9f9',
                            cursor: 'pointer'
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button auto flat color="default" onClick={() => onOpenChange(false)}>
                        Cancel
                    </Button>
                    <Button auto onClick={handleCreateFeedback} color="primary">
                        Create
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}